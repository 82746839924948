<template>
  <div class="google-map">
    <div id="map"></div>
  </div>
</template>

<script>
import {loadGoogleMaps} from '@common/utils/script.loader';

export default {
  props: {
    lat: Number,
    lng: Number,
  },
  data() {
    return {
      map: null,
    };
  },
  created() {
    loadGoogleMaps().then(google => {
      this.showPlace();
    });
  },
  methods: {
    showPlace() {
      const location = {lat: this.lat, lng: this.lng};
      const element = document.getElementById('map');
      this.map =
        this.map ||
        new google.maps.Map(element, {
          zoom: 15,
          mapTypeId: 'hybrid',
          animation: google.maps.Animation.DROP,
          disableDefaultUI: true,
          center: location,
          mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        });
      new google.maps.marker.AdvancedMarkerElement({position: location, map: this.map});
      this.map.setCenter(location);
    },
  },
};
</script>

<style lang="scss" scoped>
.google-map {
  width: 100%;
  background-color: $background-alternate-color;
  height: 300px;

  #map {
    width: 100%;
    height: 100%;
  }
}
</style>
